.pageContainer {
  max-width: 1416px;
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
}

@media (min-width: 48rem) {
  .pageContainer {
    padding: 1rem;
  }
}

@media (min-width: 92.5em) {
  .pageContainer {
    padding: 0;
  }
}