.logo {
    width: 80px;
    height: 80px;
    background-color: #2c1f96;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
}

.logo:visited {
    color: white;
}

.logoText {
    font-size: 2.65rem;
    font-family: 'Rotor', sans-serif;
    font-weight: 700;
    font-variation-settings: "RTTX" 90, "DPTH" 50;
    transition: all 250ms cubic-bezier(1, 0, 0, 1);
}

.logo:hover .logoText {
    font-variation-settings: "RTTX" 0, "DPTH" 0;
}

@media (prefers-reduced-motion) {
    .logoText {
        font-variation-settings: "RTTX" 0, "DPTH" 0, "OFST" 0;
    }
}