.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 7rem;
}

@media screen and (min-width: 48rem) {
    .headerContainer {
        flex-direction: row;
        height: auto;
        align-items: center;
    }
}

@media screen and (max-width: 48rem) {
    .logo {
        margin-top: -1rem;
    }
}

@media screen and (min-width: 48rem) {
    .nav {
        flex-grow: 1;
    }
}

.navList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.navItem {
    display: flex;
    align-items: center; 
}

.navItem:not(:nth-last-child(1))::after {
    content: "\25CF";
    font-size: 0.25rem;
}

@media screen and (min-width: 48rem) {
    .navItem::after,
    .navItem:first-of-type::before,
    .navItem:not(:nth-last-child(1))::after {
        content: "\25CF";
        font-size: 0.50rem;
    }
}

.navLink {
    font-size: 0.975rem;
    padding: 0 0.75rem;
    text-decoration: none;
}

@media screen and (min-width: 48rem) {
    .navLink {
        font-size: 1rem;
        padding: 0 1.5rem;    
    }
}

.navLink,
.navLink:visited {
    color: #2F2F37;
}

.navLink:hover {
    color: #4025ac;
}

@media screen and (max-width: 48rem) {
    .contactLink {
        display: none;
    }
}