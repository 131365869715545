
.tagGroup {
    display: flex;
    align-items: center;
}

.tagTitle {
    margin: 0;
}

.tags {
    list-style: none;
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.tagsSmall {
    padding: 0;
    margin-bottom: 1em;
    gap: 0.5rem;
}



.tag:not(:last-of-type):after {
    content: "\002F";
    font-size: 0.50rem;
    padding-left: 1em;
}

